var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "500", height: "225", zIndex: "99999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.headingTitle)),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Amount")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Amount", type: "number", min: "0" },
                model: {
                  value: _vm.balance.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.balance, "amount", _vm._n($$v))
                  },
                  expression: "balance.amount",
                },
              }),
              _c("div", { staticClass: "mt-1" }, [
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Amount"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [_vm._v("Reference")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:25",
                    expression: "'max:25'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Reference" },
                model: {
                  value: _vm.balance.reference,
                  callback: function ($$v) {
                    _vm.$set(_vm.balance, "reference", $$v)
                  },
                  expression: "balance.reference",
                },
              }),
              _c("div", { staticClass: "mt-1" }, [
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Reference"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-2" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "w-full mt-2",
                  model: {
                    value: _vm.balance.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.balance, "isActive", $$v)
                    },
                    expression: "balance.isActive",
                  },
                },
                [_vm._v("Active")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveBalance },
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }