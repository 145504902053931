var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material mb-3",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.usageList,
          gridOptions: _vm.gridOptions,
          animateRows: true,
          domLayout: "autoHeight",
          rowSelection: "single",
          pagination: false,
          suppressPaginationPanel: true,
        },
        on: {
          "first-data-rendered": _vm.onFirstDataRendered,
          "grid-ready": _vm.onGridReady,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex items-start mt-5 mt-base mb-4" }, [
      _c("h5", { staticClass: "mt-1 font-medium" }, [_vm._v("Usages")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }