var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c(
        "vx-card",
        [
          _vm.giftVoucherId
            ? _c("div", { staticClass: "vx-row mb-4" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Code")]),
                ]),
                _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                  _c("p", { staticClass: "w-full mb-3" }, [
                    _vm._v(_vm._s(_vm.form.code)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Name*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:50",
                      expression: "'required|max:50'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Name" },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Name"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Customer")]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
              _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                _c(
                  "div",
                  { staticStyle: { width: "90%" } },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "",
                          expression: "''",
                        },
                      ],
                      attrs: {
                        disabled:
                          !_vm.customerOptions ||
                          _vm.customerOptions.length == 0,
                        reduce: (option) => option.code,
                        name: "Customer",
                        options: _vm.customerOptions,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.customerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerId", $$v)
                        },
                        expression: "form.customerId",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Customer"))),
                    ]),
                    !_vm.customerOptions || _vm.customerOptions.length == 1
                      ? _c("span", { staticClass: "text-sm" }, [
                          _vm._v("Loading customer names please wait..."),
                        ])
                      : _vm._e(),
                    _vm.isReloadingCustomerNames
                      ? _c("span", { staticClass: "text-sm" }, [
                          _vm._v("Reloading customer names..."),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("vs-button", {
                      staticClass: "ml-2",
                      attrs: {
                        type: "border",
                        "icon-pack": "feather",
                        icon: "icon-refresh-cw",
                        title: "Refresh",
                      },
                      on: { click: _vm.handleCustomerLoad },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Currency*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    reduce: (option) => option.code,
                    name: "Currency",
                    options: _vm.currencyOptions,
                    clearable: false,
                  },
                  model: {
                    value: _vm.form.currencyCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "currencyCode", $$v)
                    },
                    expression: "form.currencyCode",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Currency"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Start Date")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("flat-pickr", {
                  staticClass: "w-full",
                  attrs: {
                    name: "Start Date",
                    config: { dateFormat: "Y-m-d" },
                  },
                  model: {
                    value: _vm.form.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startDate", $$v)
                    },
                    expression: "form.startDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Expiry Date")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("flat-pickr", {
                  staticClass: "w-full",
                  attrs: {
                    name: "Start Date",
                    config: { dateFormat: "Y-m-d" },
                  },
                  model: {
                    value: _vm.form.expiryDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "expiryDate", $$v)
                    },
                    expression: "form.expiryDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Internal Reference")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:25",
                      expression: "'max:25'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Internal Reference" },
                  model: {
                    value: _vm.form.internalReference,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "internalReference", $$v)
                    },
                    expression: "form.internalReference",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Internal Reference"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Is Active")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-switch", {
                  model: {
                    value: _vm.form.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isActive", $$v)
                    },
                    expression: "form.isActive",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "danger", type: "filled" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-2",
                  attrs: { color: "primary", type: "filled" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
          _vm.giftVoucherId
            ? _c("balance-list", {
                attrs: { balanceList: _vm.balanceList },
                on: {
                  onBalanceAdded: _vm.handleBalanceAdded,
                  onBalanceEdited: _vm.handleBalanceEdited,
                },
              })
            : _vm._e(),
          _vm.giftVoucherId
            ? _c("div", { staticClass: "vx-row mb-4" }, [
                _c("div", { staticClass: "vx-col w-1/5" }, [
                  _c("span", [_vm._v("Total Balance:")]),
                ]),
                _c("div", { staticClass: "vx-col w-4/5" }, [
                  _c("div", { staticClass: "ml-3" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.totalBalance) +
                          " " +
                          _vm._s(_vm.form.currencyCode)
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.giftVoucherId
            ? _c("usage-list", { attrs: { usageList: _vm.usageList } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }